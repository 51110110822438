import { acceptHMRUpdate, defineStore } from 'pinia';

export const useAgeStore = defineStore('age', () => {
  const age = useCookie<number | null>('kygunco_age_confirmed', {
    secure: true,
    sameSite: 'lax',
    maxAge: DAY_IN_SECONDS * 30,
  });

  const confirm = (confirmed: MaybeRef<number>) =>
    (age.value = toValue(confirmed));

  const check = (minimum: MaybeRef<number>) =>
    age.value ? age.value >= toValue(minimum) : false;

  return {
    age,
    confirm,
    check,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAgeStore, import.meta.hot));
}
