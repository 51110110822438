import { createEventHook } from '@vueuse/core';
import { uid } from 'quasar';
import type { KountSdkCallbackParams } from '~/types/kount';

export default defineNuxtPlugin({
  name: 'kygunco:kount',
  dependsOn: [],
  parallel: true,
  setup: () => {
    const config = useRuntimeConfig();

    const enabled = config.public.kount.enabled;
    const clientId = config.public.kount.clientId;
    const hostname = config.public.kount.hostname;
    const debug = config.public.kount.debug;

    const sessionId = ref(uid().replaceAll('-', ''));
    const ready = ref(false);

    const onCollectBegin = createEventHook<KountSdkCallbackParams>();
    const onCollectEnd = createEventHook<KountSdkCallbackParams>();

    onCollectBegin.on(() => ready.value = false);
    onCollectEnd.on(() => ready.value = true);

    const load = async () => {
      if (!enabled) {
        return;
      }

      if (!import.meta.client) {
        return;
      }

      // Defer importing the SDK until it's needed
      const { default: kountSDK } = await import('@kount/kount-web-client-sdk/kount-web-client-sdk');

      kountSDK(
        {
          clientID: clientId,
          hostname: hostname,
          isSinglePageApp: true,
          isDebugEnabled: debug,
          callbacks: {
            'collect-begin': onCollectBegin.trigger,
            'collect-end': onCollectEnd.trigger,
          },
        },
        sessionId.value,
      );
    };

    return {
      provide: {
        kount: {
          load,
          enabled,
          ready,
          sessionId,
          onCollectBegin: onCollectBegin.on,
          onCollectEnd: onCollectEnd.on,
        },
      },
    };
  },
});
