import { whenever } from '@vueuse/core';
import { acceptHMRUpdate, defineStore } from 'pinia';
import type { FetchError } from 'ofetch';
import type {
  ProblemDetails,
  QuoteRequest,
  QuoteResponse,
} from '~/types/ecommerce';

export const useQuoteStore = defineStore('quote', () => {
  const userStore = useUserStore();
  const cartStore = useCartStore();
  const checkoutStore = useCheckoutStore();
  const { $ecommerce } = useNuxtApp();

  const request = computed(() => {
    return {
      billingAddressId: checkoutStore.billingAddress?.id,
      shippingAddressId: checkoutStore.shippingAddress?.id,
      pickupLocationId: checkoutStore.pickupLocation?.id,
      fflDealerId: checkoutStore.fflDealer?.id,
      isPickup: checkoutStore.isPickup,
      isLayaway: checkoutStore.isLayaway,
      isInsured: checkoutStore.isInsured && !checkoutStore.isPickup,
      paymentMethod: checkoutStore.paymentMethod,
      storeCredits: checkoutStore.storeCredits.map(i => ({ code: i.code })),
    } as QuoteRequest;
  });

  const error = ref<ProblemDetails>();

  const { data, status, execute, refresh, clear } = useAsyncData(
    'quote',
    () =>
      $ecommerce.fetch<QuoteResponse>(`carts/${userStore.data?.cartId}/quotes`, {
        method: 'POST',
        body: request.value,
        onResponse: () => { error.value = undefined; },
      }).catch((e: FetchError<ProblemDetails>) => {
        error.value = e.data;
        return undefined;
      }),
    {
      immediate: !!userStore.data?.cartId,
      lazy: true,
    },
  );

  whenever(
    () => userStore.data && request.value,
    () => execute(),
  );

  watch(
    () => cartStore.data,
    () => refresh(),
  );

  const reset = () => clear();

  return {
    data,
    error,
    pending: computed(() => status.value == 'pending'),
    refresh,
    reset,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useQuoteStore, import.meta.hot));
}
