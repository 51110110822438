import type { NuxtApp } from '#app';

export const noop = () => {};

export const isNumeric = (num: unknown) =>
  (typeof num === 'number' || (typeof num === 'string' && num.trim() !== ''))
  && !isNaN(num as number);

export const getCachedPayload = (key: string, nuxtApp: NuxtApp) => nuxtApp.payload.data[key];

export const range = (length: number) => Array.from({ length }, (_value, index) => index);
