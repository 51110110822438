import { EventBus } from 'quasar';
import type {
  CartItemResponse,
  OrderResponse,
  ProductResponse,
  VariationResponse,
} from '~/types/ecommerce';
import type { Product } from '~/types/search';

export interface Events {
  'product:view': (product: ProductResponse, variation?: VariationResponse) => void;
  'product:add': (product: ProductResponse, variation?: VariationResponse, quantity?: number) => void;
  'catalog:add': (product: Product, collection?: string) => void;
  'catalog:search': (term: string) => void;
  'catalog:select': (product: Product, collection?: string) => void;
  'catalog:view': (products: Product[], collection?: string) => void;
  'cart:remove': (items: CartItemResponse[]) => void;
  'auth:register': () => void;
  'auth:login': () => void;
  'auth:logout': () => void;
  'newsletter:opt-in': (payload: { email: string; name?: string }) => void;
  'checkout:start': (items: CartItemResponse[]) => void;
  'checkout:cancel': (items: CartItemResponse[]) => void;
  'checkout:complete': (payload: OrderResponse[]) => void;
  'ui:interaction': (e: Event) => void;
};

type MappedEvents = {
  [key in keyof Events]: Events[key];
};

export default defineNuxtPlugin({
  name: 'kygunco:bus',
  parallel: true,
  setup: () => {
    const bus = new EventBus<MappedEvents>();

    /**
     * This will perform a lot better than using onInteraction() composable for first interaction.
     * For any other use case just use onInteraction() directly wherever it's needed.
     *
     * Note: For first interaction detection subscribe with "once":
     * $bus.once('ui:interaction', (e) => {});
     */
    onInteraction((e: Event) => bus.emit('ui:interaction', e));

    return {
      provide: {
        bus,
      },
    };
  },
});
