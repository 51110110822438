import type { CreditCard, CreditCardType } from '~/types/ecommerce';

const getTypeLabel = (type: CreditCardType | null | undefined) => {
  switch (type) {
    case 'american-express':
      return 'American Express';
    case 'diners-club':
      return 'Diners Club';
    case 'discover':
      return 'Discover';
    case 'elo':
      return 'Elo';
    case 'hiper':
      return 'Hiper';
    case 'hipercard':
      return 'Hipercard';
    case 'jcb':
      return 'JCB';
    case 'maestro':
      return 'Maestro';
    case 'mastercard':
      return 'Mastercard';
    case 'mir':
      return 'Mir';
    case 'unionpay':
      return 'UnionPay';
    case 'visa':
      return 'Visa';
    default:
      return undefined;
  }
};

export const useCreditCard = (entity: MaybeRefOrGetter<CreditCard>) => {
  const card = computed(() => toValue(entity));

  const type = computed(() => getTypeLabel(card.value.type));

  const last4 = computed(() =>
    card.value.isValid ? card.value.number?.slice(-4) : undefined,
  );

  const masked = computed(() =>
    card.value.isValid ? `${type.value} ends in X${last4.value}` : undefined,
  );

  return {
    type,
    last4,
    masked,
  };
};
