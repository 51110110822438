<!-- eslint-disable vue/no-v-html -->
<template>
  <NuxtLayout>
    <q-page
      padding
      class="column justify-center"
    >
      <div class="text-center q-my-lg">
        <h1 class="text-weight-medium q-my-sm">
          {{ error.statusCode }}
        </h1>
        <div class="text-h5 text-grey">
          {{ error.message ?? error.statusMessage }}
        </div>
        <div
          v-if="showStack"
          v-html="error.stack"
        />
      </div>
      <div class="row flex-center q-gutter-md q-mb-lg">
        <q-btn
          v-if="error.statusCode == 401"
          color="primary"
          size="lg"
          padding="sm xl"
          label="Login"
          square
          @click="dialog({
            component: LazyDialogAuthLoginRegister,
            componentProps: {
              redirect: route.fullPath,
            },
          });"
        />
        <q-btn
          v-else-if="error.statusCode == 429"
          color="primary"
          size="lg"
          padding="sm xl"
          label="Login"
          square
          @click="router.go(0)"
        />
        <q-btn
          v-else
          href="/"
          color="primary"
          size="lg"
          padding="sm xl"
          label="Continue Shopping"
          square
        />
      </div>
    </q-page>
  </NuxtLayout>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app';
import { LazyDialogAuthLoginRegister } from '#components';

const props = defineProps({
  error: {
    required: true,
    type: Object as () => NuxtError,
  },
});

const { dialog } = useQuasar();

const route = useRoute();
const router = useRouter();

const showStack = computed(() =>
  import.meta.dev && !!props.error.stack && props.error.statusCode == 500);

useSeoMeta({
  title: props.error.message ?? props.error.statusMessage ?? 'Unknown',
  titleTemplate: '%s %separator Error %separator KYGUNCO',
});
</script>
