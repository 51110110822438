import revive_payload_client_4sVQNw7RlN from "/workspace/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/workspace/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/workspace/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/workspace/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/workspace/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/workspace/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/workspace/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/workspace/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/workspace/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/workspace/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/workspace/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_dw2T9lEw4h from "/workspace/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import siteConfig_izaWKZ8rEu from "/workspace/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_Uk9bcXDHeN from "/workspace/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import strapi_CICqBBANNd from "/workspace/node_modules/@nuxtjs/strapi/dist/runtime/plugins/strapi.mjs";
import plugin_client_UYiXMU8ZyN from "/workspace/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import nuxt_plugin_6wEQMY3tee from "/workspace/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import plugin_sb2LSEEy8f from "/workspace/node_modules/nuxt-quasar-ui/dist/runtime/plugin.js";
import _00_ecommerce_h7TcpfkE28 from "/workspace/plugins/00.ecommerce.ts";
import _00_typesense_an4G8zVhq4 from "/workspace/plugins/00.typesense.ts";
import accessibly_client_2tJgKXqcfL from "/workspace/plugins/accessibly.client.ts";
import analytics_client_mtqVw3YRXk from "/workspace/plugins/analytics.client.ts";
import avantlink_IcQGxZmS8Z from "/workspace/plugins/avantlink.ts";
import bus_RrsLoRnN1Z from "/workspace/plugins/bus.ts";
import clarity_FoYTnyX1Oc from "/workspace/plugins/clarity.ts";
import credova_6kutWhLazg from "/workspace/plugins/credova.ts";
import kount_FS9jK2y5J6 from "/workspace/plugins/kount.ts";
import marketing_1nGyY8ktKC from "/workspace/plugins/marketing.ts";
import tawk_tUlyaFnual from "/workspace/plugins/tawk.ts";
import defaults_aRl2YLnTcb from "/workspace/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  titles_dw2T9lEw4h,
  siteConfig_izaWKZ8rEu,
  inferSeoMetaPlugin_Uk9bcXDHeN,
  strapi_CICqBBANNd,
  plugin_client_UYiXMU8ZyN,
  nuxt_plugin_6wEQMY3tee,
  plugin_sb2LSEEy8f,
  _00_ecommerce_h7TcpfkE28,
  _00_typesense_an4G8zVhq4,
  accessibly_client_2tJgKXqcfL,
  analytics_client_mtqVw3YRXk,
  avantlink_IcQGxZmS8Z,
  bus_RrsLoRnN1Z,
  clarity_FoYTnyX1Oc,
  credova_6kutWhLazg,
  kount_FS9jK2y5J6,
  marketing_1nGyY8ktKC,
  tawk_tUlyaFnual,
  defaults_aRl2YLnTcb
]