import type { AccessiblyOptions } from '~/types/accessibly';

declare global {
  interface Window {
    Accessibly: {
      init: (settings: Partial<AccessiblyOptions>) => void;
    };
  }
}

export default defineNuxtPlugin({
  name: 'kygunco:accessibly',
  dependsOn: ['kygunco:bus'],
  parallel: true,
  setup: () => {
    const config = useRuntimeConfig().public.accessibly;

    const { $bus } = useNuxtApp();

    const enabled = config.enabled && !!config.domain;

    const { load, onLoaded } = useScript(
      {
        'src': 'https://cdn.accessibly.app/accessibility-widget-v2.min.js',
        'data-accessibly-domain': config.domain,
        'async': true,
        'referrerpolicy': false,
        'crossorigin': false,
      },
      {
        trigger: 'manual',
        mode: 'client',
        use: () => window.Accessibly,
      });

    onLoaded(({ init }) => init(config.options as Partial<AccessiblyOptions>));

    $bus.once('ui:interaction', () => !enabled || load());
  },
});
