export const onInteraction = (callback: (event: Event) => void) => {
  const events = ['mousemove', 'scroll', 'keydown', 'click', 'touchstart', 'wheel'];

  const addEventListeners = () =>
    events.forEach(event => window.addEventListener(event, listener));

  const removeEventListeners = () =>
    events.forEach(event => window.removeEventListener(event, listener));

  const listener = (event: Event) => {
    removeEventListeners();
    callback(event);
  };

  onNuxtReady(() => addEventListeners());
};
