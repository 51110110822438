import { uid } from 'quasar';

declare global {
  interface Window {
    _AvantMetrics: unknown[];
  }
}

export default defineNuxtPlugin({
  name: 'kygunco:avantlink',
  dependsOn: ['kygunco:bus'],
  parallel: true,
  setup: () => {
    const config = useRuntimeConfig();

    const enabled
      = !!config.public.avantlink?.enabled && !!config.public.avantlink?.siteId;

    const { $bus } = useNuxtApp();

    /**
     * This is the main script that exposes the API
     * */
    const { status, onError, load } = useScript(
      {
        src: `https://cdn.avmws.com/${config.public.avantlink.siteId}`,
        referrerpolicy: false,
        crossorigin: false,
        key: uid(),
      },
      {
        trigger: 'manual',
        mode: 'client',
        use: () => ({
          avantlink: window._AvantMetrics,
          push: (type: string, payload: unknown) => {
            window._AvantMetrics = window._AvantMetrics || [];
            window._AvantMetrics.push([type, payload]);
          },
        }),
      });

    /**
     * This is the tracker script that properly pushes window._AvantMetrics
     */
    const tracker = useScript(
      {
        src: `https://cdn.avmws.com/${config.public.avantlink.siteId}`,
        referrerpolicy: false,
        crossorigin: false,
        key: uid(),
      },
      {
        trigger: 'manual',
        mode: 'client',
      });

    /**
     * Since this script is not critical to the site, we'll just catch the exception and do nothing.
     * Calling push() will only append to an array in window._AvantMetrics which should be safe to
     * do without the script.
     */
    onError(noop);

    const ready = computed(() => status.value == 'loaded');

    const push = (type: string, payload: unknown) => {
      window._AvantMetrics.push([type, payload]);
    };

    $bus.once('ui:interaction', () => !enabled || load());

    $bus.on('checkout:complete', async (orders) => {
      for (const order of orders) {
        push('order', {
          order_id: order.id,
          amount: order.grandTotal,
          state: order.billingAddress.state,
          country: order.billingAddress.country,
          currency: 'USD',
        });

        for (const item of order.items) {
          push('item', {
            order_id: order.id,
            parent_sku: item.productId,
            variant_sku: item.variationId,
            price: item.price,
            qty: item.quantity,
          });
        }
      }

      await tracker.load();
    });

    return {
      provide: {
        avantlink: {
          enabled,
          ready,
        },
      },
    };
  },
});
