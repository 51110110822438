import type { BaseAddress } from '~/types/ecommerce';

interface FormatMoneyOptions {
  separator?: string; // Grouping separator (,)
  decimalPoint?: string; // Sets the separator for the decimal point.
  decimals?: number; // Sets the number of decimal points.
  symbol?: string;
  append?: boolean;
  leadZeros?: boolean;
}

const defaults: FormatMoneyOptions = {
  separator: ',',
  decimalPoint: '.',
  decimals: 2,
  symbol: '$',
  append: false,
  leadZeros: true,
};

const formatCurrency = (
  value: number,
  options?: FormatMoneyOptions,
): string | undefined => {
  // Merge custom options
  const customOptions = {
    ...defaults,
    ...options,
  };

  // If value not number return undefined
  if (typeof value !== 'number') {
    return undefined;
  }

  // If value is NaN
  if (Number.isNaN(value)) {
    return undefined;
  }

  // Set a sign for negative number
  let negativeSign: string = value < 0 ? '-' : '';
  let result: string;
  let left: string;
  let body: string;
  let prefix = '';
  let suffix = '';

  result = Math.abs(value)
    .toFixed(customOptions.decimals)
    .replace(/\.0{1,}$/, '');

  if (parseFloat(result) === 0 || result === '0') {
    negativeSign = '';
  }

  if (!customOptions.leadZeros) {
    const resultFloat = parseFloat(result);
    result = resultFloat.toString();
  }

  const resultArr: string[] = result.split('.');
  [left] = resultArr;

  const right
    = resultArr.length > 1 ? customOptions.decimalPoint! + resultArr[1] : '';
  if (customOptions.separator) {
    body = '';
    for (let i = 0, len = left.length; i < len; i += 1) {
      if (i !== 0 && i % 3 === 0) {
        body = customOptions.separator + body;
      }
      body = left[len - i - 1] + body;
    }
    left = body;
  }

  if (customOptions.append) {
    suffix = customOptions.symbol as string;
  }
  else {
    prefix = customOptions.symbol as string;
  }

  return negativeSign + prefix + left + right + suffix;
};

const formatPercent = (value: number): string => {
  return `${value.toFixed()}%`;
};

const pluralize = (count: number, noun: string, suffix = 's') =>
  `${count} ${noun}${count !== 1 ? suffix : ''}`;

const formatRating = (value: number) => {
  return value.toFixed(2).replace(/0{1}$/, '');
};

const formatPhoneNumber = (phoneNumber: string) => {
  return phoneNumber
    .replace(/\D/g, '')
    .replace(
      /(\d*)(\d{3})(\d{3})(\d{4})$/,
      (s, a, b, c, d) => `+${a} (${b}) ${c}-${d}`,
    )
    .replace(/\+(1\b|\s)\s*/, '');
};

const formatAddress = (address: MaybeRefOrGetter<BaseAddress>) =>
  join(
    concat(
      ', ',
      toValue(address).street,
      toValue(address).street2,
      toValue(address).city,
      toValue(address).state,
    ),
    toValue(address).postcode,
  );

const concat = (separator: string, ...parts: (string | null | undefined)[]) => {
  return parts
    .filter(p => !!p?.length)
    .join(separator)
    .trim();
};

const join = (...parts: (string | null | undefined)[]) => {
  return concat(' ', ...parts);
};

const maskify = (value: string, last = 4, char = 'X') =>
  value.slice(0, last * -1).replace(/./g, char) + value.slice(last * -1);

export const paragraphize = (text: string) => {
  if (!text?.trim()?.length) {
    return;
  }

  return text?.trim().split(/\n\n|\r\n\r\n/).map(p => `<p>${p}</p>`).join('\n');
};

export const useFormatting = () => {
  return {
    currency: formatCurrency,
    percent: formatPercent,
    rating: formatRating,
    phone: formatPhoneNumber,
    address: formatAddress,
    maskify,
    pluralize,
    paragraphize,
    concat,
    join,
  };
};
