export default defineNuxtPlugin({
  name: 'kygunco:analytics',
  dependsOn: ['kygunco:bus'],
  parallel: true,
  setup: () => {
    const { $bus } = useNuxtApp();

    const userStore = useUserStore();

    $bus.on('product:view', (product, variation) =>
      useTrackEvent('view_item', {
        currency: 'USD',
        value: product.price,
        items: [
          {
            item_id: product.id,
            item_name: product.name,
            item_brand: product.manufacturer?.name,
            item_variant: variation?.identifier,
            price: product.price,
          },
        ],
      }),
    );

    $bus.on('product:add', (product, variation, quantity) =>
      useTrackEvent('add_to_cart', {
        currency: 'USD',
        value: (variation?.price ?? product.price) * (quantity ?? 1),
        items: [
          {
            item_id: product.id,
            item_name: product.name,
            item_brand: product.manufacturer?.name,
            item_variant: variation?.identifier,
            price: variation?.price ?? product.price,
            quantity,
          },
        ],
      }),
    );

    $bus.on('catalog:search', term =>
      useTrackEvent('search', { search_term: term }),
    );

    $bus.on('catalog:view', (products, collection) =>
      useTrackEvent('view_item_list', {
        item_list_id: collection ? snakeCase(collection) : undefined,
        item_list_name: collection,
        items: products.map((p, i) => ({
          item_id: p.id,
          item_name: p.name,
          price: p.price,
          index: i,
        })),
      }),
    );

    $bus.on('catalog:select', (product, collection) =>
      useTrackEvent('select_item', {
        item_list_id: collection ? snakeCase(collection) : undefined,
        item_list_name: collection,
        items: [
          {
            item_id: product.id,
            item_name: product.name,
            price: product.price,
          },
        ],
      }),
    );

    $bus.on('catalog:add', (product, collection) =>
      useTrackEvent('add_to_cart', {
        currency: 'USD',
        value: product.price,
        items: [
          {
            item_id: product.id,
            item_name: product.name,
            item_list_id: collection ? snakeCase(collection) : undefined,
            item_list_name: collection,
            price: product.price,
          },
        ],
      }),
    );

    $bus.on('cart:remove', items =>
      useTrackEvent('remove_from_cart', {
        currency: 'USD',
        value: items.map(i => i.total).reduce((a, b) => a + b, 0),
        items: items.map(i => ({
          item_id: i.productId,
          item_variant: i.variationId ?? undefined,
          item_name: i.name,
          price: i.price,
          quantity: i.quantity,
        })),
      }),
    );

    $bus.on('auth:login', () =>
      useTrackEvent('login', {
        method: 'KYGUNCO',
      }),
    );

    $bus.on('auth:register', () =>
      useTrackEvent('sign_up', {
        method: 'KYGUNCO',
      }),
    );

    $bus.on('checkout:start', items =>
      useTrackEvent('begin_checkout', {
        currency: 'USD',
        value: items.map(i => i.total).reduce((a, b) => a + b, 0),
        items: items.map(i => ({
          item_id: i.productId,
          item_variant: i.variationId ?? undefined,
          item_name: i.name,
          price: i.price,
          quantity: i.quantity,
        })),
      }),
    );

    $bus.on('checkout:complete', (orders) => {
      for (const order of orders) {
        useTrackEvent('purchase', {
          currency: 'USD',
          id: order.id,
          value: order.grandTotal,
          shipping: order.totals.find(t => t.description === 'Shipping and Handling')?.total,
          tax: order.totals.find(t => t.description === 'Sales Tax')?.total,
          items: order.items.map(i => ({
            item_id: i.productId,
            item_variant: i.variationId ?? undefined,
            item_name: i.name,
            price: i.price,
            quantity: i.quantity,
          })),
          email: userStore.data?.email,
        });
      }
    });
  },
});
