import { useToggle } from '@vueuse/core';
import { acceptHMRUpdate, defineStore } from 'pinia';
import type { FetchError } from 'ofetch';
import type {
  CartItem,
  CartItemResponse,
  CartResponse,
  ProblemDetails,
} from '~/types/ecommerce';

export const useCartStore = defineStore('cart', () => {
  const { $bus } = useNuxtApp();
  const authStore = useAuthStore();
  const { $ecommerce } = useNuxtApp();

  const guestCartId = useCookie('kygunco_guest_cart_id', {
    secure: true,
    sameSite: 'lax',
    maxAge: YEAR_IN_SECONDS,
  });

  const [visible, toggle] = useToggle();

  const cartId = computed(() => authStore.cartId ?? guestCartId.value);

  const saving = ref(false);

  const { data, status, execute, refresh } = useAsyncData(
    'cart',
    () => $ecommerce.fetch<CartResponse>(`carts/${cartId.value}`)
      .catch((error: FetchError<ProblemDetails>) => {
        // Handles the guest cart not existing anymore
        if (error.status == 404) {
          guestCartId.value = null;
          return;
        }

        $ecommerce.handle(error);

        return undefined;
      }),
    {
      immediate: !!cartId.value,
    },
  );

  const save = async (items: CartItem[] = []) => {
    const path = cartId.value ? `carts/${cartId.value}` : 'carts';
    const method = cartId.value ? 'PUT' : 'POST';

    saving.value = true;

    const response = await $ecommerce.fetch<CartResponse>(path, {
      method: method,
      body: { items },
    }).catch((error: FetchError<ProblemDetails>) => {
      // Handles the guest cart not existing anymore
      if (error.status == 404) {
        guestCartId.value = null;
        return undefined;
      }

      $ecommerce.handle(error);

      return undefined;
    });

    saving.value = false;

    if (method == 'POST') {
      // Persist the guest cart id
      guestCartId.value = response?.id;
    }

    data.value = response;
  };

  const add = async (item: CartItem) => {
    if (!data.value) {
      await save([item]);
      return;
    }

    const items = data.value.items.map(
      i =>
        ({
          productId: i.productId,
          quantity: i.quantity,
          variationId: i.variationId,
        }) as CartItem,
    );

    const index = items.findIndex(
      i => i.productId == item.productId && i.variationId == item.variationId,
    );

    const _ = index >= 0 ? (items[index]!.quantity += item.quantity) : items.push(item);

    await save(items);

    visible.value = true;
  };

  const update = async (item: CartItemResponse) => {
    if (!data.value) {
      // TODO: handle this edge case
      return;
    }

    const items = data.value.items;

    const index = items.findIndex(i => i.id == item.id);

    const _ = index >= 0 ? (items[index] = item) : items.push(item);

    await save(
      items.map(
        i =>
          ({
            productId: i.productId,
            quantity: i.quantity,
            variationId: i.variationId,
          }) as CartItem,
      ),
    );
  };

  const clear = async () => {
    const items = data.value?.items ?? [];

    await save([]);

    if (items.length) {
      $bus.emit('cart:remove', items);
    }
  };

  const remove = async (item: CartItemResponse) => {
    if (!data.value) {
      // TODO: handle this edge case
      return;
    }

    await save(
      data.value.items
        .filter(i => i.id != item.id)
        .map(
          i =>
            ({
              productId: i.productId,
              quantity: i.quantity,
              variationId: i.variationId,
            }) as CartItem,
        ),
    );

    $bus.emit('cart:remove', [item]);
  };

  const pending = computed(() => status.value == 'pending' || saving.value);

  const class3 = computed(
    () => !!data.value && data.value.items.some(i => i.isClass3),
  );

  watch(cartId, async (value) => {
    if (!value) {
      data.value = undefined;
      return;
    }

    await execute();
  });

  $bus.on('checkout:complete', () => refresh());

  return {
    data,
    pending,
    visible,
    guestCartId,
    class3,
    toggle,
    refresh,
    add,
    update,
    remove,
    clear,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCartStore, import.meta.hot));
}
